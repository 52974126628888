import Vue from 'vue';
import { Capacitor } from '@capacitor/core';
import { StatusBar } from '@capacitor/status-bar';
import { Preferences } from '@capacitor/preferences';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { Share } from '@capacitor/share';

// STORAGE
const setIsLogged = async (value) => {
  console.log('[APP] save is logged', value);
  await Preferences.set({
    key: 'isLogged',
    value: String(value),
  });
};
const getIsLogged = async () => {
  const { value } = await Preferences.get({ key: 'isLogged' });
  console.log('[APP] get is logged', value);
  return value ? value === 'true' : false;
};
const removeIsLogged = async () => {
  console.log('[APP] remove is logged');
  await Preferences.remove({ key: 'isLogged' });
};
const setAppRefreshToken = async (value) => {
  console.log('[APP] save refresh token', value);
  await Preferences.set({
    key: 'refreshToken',
    value,
  });
};
const getAppRefreshToken = async () => {
  const { value } = await Preferences.get({ key: 'refreshToken' });
  console.log('[APP] get refresh token', value);
  return value;
};
const removeAppRefreshToken = async () => {
  console.log('[APP] remove refresh token');
  await Preferences.remove({ key: 'refreshToken' });
};
const setAppLastUrl = async (value) => {
  console.log('[APP] save last url', value);
  await Preferences.set({
    key: 'lastOpenedUrl',
    value,
  });
};
const getAppLastUrl = async () => {
  const { value } = await Preferences.get({ key: 'lastOpenedUrl' });
  console.log('[APP] get last url', value);
  return value || null;
};
const removeAppLastUrl = async () => {
  console.log('[APP] remove last url');
  await Preferences.remove({ key: 'lastOpenedUrl' });
};


// DEVICE
const generateAppFingerprint = async () => {
  const uuid = await Device.getId();
  console.log('[APP] generate uuid', uuid.identifier);
  return uuid.identifier;
};
const getDeviceInfo = async () => {
  const info = await Device.getInfo();
  return info;
};


// STATUSBAR
function changeStatusBarBg (theme) {
  const color = theme === 'dark' ? '#1d1e20' : '#1b4c80';
  StatusBar.setBackgroundColor({ color });
}
function initStatusBarController (store) {
  changeStatusBarBg(store.getters.currentTheme);

  Vue.prototype.$bus.$on('theme-changed', (theme) => {
    changeStatusBarBg(theme);
  });
}


// BADGE
/* const getBadgeCount = async () => {
  const result = await Badge.get();
  console.log('[APP] get badge count', result.count);
  return result.count;
};
const increaseBadgeCount = async () => {
  console.log('[APP] increase badge count');
  await Badge.increase();
};
const clearBadgeCount = async () => {
  await Badge.clear();
};
const isBadgeSupported = async () => {
  const result = await Badge.isSupported();
  console.log('[APP] badge count supported?', result.isSupported);
  return result.isSupported;
};
const initAppBadge = async () => {
  if (!isBadgeSupported) return;

  let permission = await Badge.checkPermissions();
  console.log('[APP] badge count permission', permission.display);

  if (permission.display === 'prompt') {
    permission = await Badge.requestPermissions();
    console.log('[APP] badge count permission 12', permission.display);
  }

  if (permission.display !== 'granted') {
    throw new Error('[APP] User denied badge permissions!');
  }
}; */


// BG RUNNER
/* async function initAppBgRunner () {
  try {
    const permissions = await BackgroundRunner.requestPermissions({
      apis: ['notifications', 'geolocation'],
    });
    console.log('[APP] set bg permissions', permissions);
  } catch (err) {
    console.log(`[APP] error bg permissions: ${err}`);
  }
} */


// SHARE
const shareUrl = async (url) => {
  try {
    const permission = await Share.canShare();
    if (!permission.value) return;
    await Share.share({
      url,
    });
  } catch (e) {
    console.error('[APP] Error : ', e);
  }
};


// APP
async function initAppController (ctx) {
  const path = await getAppLastUrl();
  console.log('[APP] last url: ', path);
  if (path) ctx.app.router.push({path});

  App.addListener('appUrlOpen', function (event) {
    console.log('[APP] url open ', event.url);
    // url = https://agent.razlet.kg/account/profile
    // slug = /account/profile
    const slug = event.url.split('.kg').pop();
  
    if (slug) {
      ctx.app.router.push({
        path: slug,
      });
    }
  });
  App.addListener('backButton', (data) => {
    if (data.canGoBack) window.history.back();
    else App.exitApp();
  });
  App.addListener('appStateChange', async (data) => {
    console.log('[APP] App state changed.', data ? JSON.stringify(data) : data);
    if (!data.isActive) await setAppLastUrl(ctx.route.fullPath);
  });
  App.addListener('appRestoredResult', data => {
    console.log('[APP] Restored state:', data ? JSON.stringify(data) : data);
  });
  App.addListener('pause', async (data) => {
    console.log('[APP] Pause:', data ? JSON.stringify(data) : data);
    await setAppLastUrl(ctx.route.fullPath);
  });
  App.addListener('resume', data => {
    console.log('[APP] Resume:', data ? JSON.stringify(data) : data);
  });
}


export default async (ctx) => {
  if (Capacitor.isNativePlatform()) {
    initAppController(ctx);
    initStatusBarController(ctx.store);

    Vue.prototype.$getNativeRefreshToken = getAppRefreshToken;
    Vue.prototype.$setNativeRefreshToken = setAppRefreshToken;
    Vue.prototype.$removeNativeRefreshToken = removeAppRefreshToken;

    Vue.prototype.$getAppFingerprint = generateAppFingerprint;

    Vue.prototype.$getNativeLastUrl = getAppLastUrl;
    Vue.prototype.$setNativeLastUrl = setAppLastUrl;
    Vue.prototype.$removeNativeLastUrl = removeAppLastUrl;
  }
  Vue.prototype.$getIsLogged = getIsLogged;
  Vue.prototype.$setIsLogged = setIsLogged;
  Vue.prototype.$removeIsLogged = removeIsLogged;

  Vue.prototype.$shareUrl = shareUrl;

  Vue.prototype.$deviceInfo = getDeviceInfo;
};